'use client'

import Field from 'components/form/Field'
import Form from 'components/form/Form'
import Link from 'components/router/Link'
import { lostPasswordPath, myProfilePath } from 'shared/urlHelpers'
import {
  formSubmitValidation,
  formValidation
} from 'components/form/formValidation'
import { FormSubmissionPanel } from 'components/form/FormSubmissionPanel'
import { signIn } from 'lib/session-client'
import { ResendVerificationEmail } from 'components/auth/ResendVerificationEmail'
import { useRouter } from 'lib/useRouter'
import { ComponentType, useState } from 'react'

interface Props {
  backTo?: string
}

interface LoginFormProps {
  email: string
  password: string
}

const validate = (d: LoginFormProps) =>
  formValidation<LoginFormProps>(d, {
    email: ['required', 'email'],
    password: ['required', 'password']
  })

const LoginForm: ComponentType<Props> = ({ backTo }: Props) => {
  const [notVerifiedEmailToken, setNotVerifiedEmailToken] = useState<
    string | null
  >(null)

  const router = useRouter()

  const formSubmit = ({ email, password }: LoginFormProps) => {
    setNotVerifiedEmailToken(null)
    return signIn(email.trim(), password)
      .then((resp) => {
        router.push(backTo?.toString() || myProfilePath())
      })
      .catch((error) => {
        if (error.jwt) {
          setNotVerifiedEmailToken(error.jwt)
        }
        return formSubmitValidation([{ field: 'base', message: error.message }])
      })
  }

  return (
    <>
      {notVerifiedEmailToken && (
        <div className={'view-vertical space-y-2 items-start'}>
          <p className="prose">
            Dein Benutzerkonto ist noch nicht aktiv. Du musst zuerst deine
            E-Mail-Adresse bestätigen.
          </p>
          <ResendVerificationEmail token={notVerifiedEmailToken} />
        </div>
      )}

      {!notVerifiedEmailToken && (
        <Form
          className="w-full md:w-1/2 form"
          onSubmit={formSubmit}
          validate={validate}
        >
          <Field
            name="email"
            label="E-Mail"
            className="mb-4"
            input={(p) => (
              <input {...p} type="email" required autoComplete="username" />
            )}
          />

          <Field
            name="password"
            label="Passwort"
            className="mb-1"
            input={(p) => (
              <input
                {...p}
                type="password"
                required
                autoComplete="current-password"
              />
            )}
          />

          <Link
            href={lostPasswordPath()}
            className="block mt-4 mb-4 text-xs text-gray-500 underline"
          >
            Passwort vergessen?
          </Link>
          <FormSubmissionPanel buttonText="Anmelden" />
        </Form>
      )}
    </>
  )
}

export default LoginForm
